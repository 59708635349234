import { computed, useSignalEffect } from '@preact/signals-react';
import { Select } from 'antd';
import { Cancel02Icon, Infinity01Icon } from 'hugeicons-react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import { useLazyGetUserProfileQuery } from '../../api/apiSlice';
import { userSignal } from '../../signals/global.signals';
import { Typography } from '../../stories/Typography';
import SwitchBtn from '../Switch/SwitchBtn';

import { isSubscribedComputed, isSubscribingComputed, SubscriptionSignal } from './ModalCheckoutLink.signal';
import {
    ModalCheckoutContainer,
    ModalCheckoutContent,
    PortalBtn,
    ProductButton,
    ProductContainer,
    SelectCheckoutDuration,
    SubscribedUntilContainer,
    SubscribedUntilTextContainer
} from './ModalCheckoutLink.style';
const { Option } = Select;

const stripePortalUrlComputed = computed(() => userSignal.value?.userSubscription?.portalLink) ;


const ModalCheckoutLink = ({  onClose }) => {
  const {t} = useTranslation();
  const [localShowModal, setLocalShowModal] = useState(false);
  const [isProfessionalPrice, setIsProfessionalPrice] = useState(true);
  const [selectedDurations, setSelectedDurations] = useState({
    Lite: 'one_month',
    Pro: 'one_month',
    'Pro +': 'one_month'
  });
  const [hasTofetchuser, setHasToFetchUser] = useState(true);
  const products = process.env.REACT_APP_NODE_ENV === 'development' ? [
    {
      name: 'Lite',
      sessions: (
        <Typography fz="20px" fw="500" lh="1.2" padding="0 6px">
          6
        </Typography>
      ),
      sessionsIcon: null,
      programs: <Cancel02Icon />,

      paymentLinks: {
        duration: {
          one_month: {
            regular: 'https://buy.stripe.com/test_dR62c07JK3Y78cU6oo',
            professional: 'https://buy.stripe.com/test_dR62c07JK3Y78cU6oo',
            price: 9
          }
          // three_month: {
          //   regular: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
          //   professional: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
          //   price: 10
          // },
          // one_year: {
          //   regular: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
          //   professional: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
          //   price: 11
          // }
        }
      }
    },
    {
      name: 'Pro',
      sessions: '∞',
      sessionsIcon: <Infinity01Icon color={css`var(--accent-color)`} />,
      programs: <Cancel02Icon />,

      paymentLinks: {
        duration: {
          one_month: {
            regular: 'https://buy.stripe.com/test_dR62c07JK3Y78cU6oo',
            professional: 'https://buy.stripe.com/test_dR62c07JK3Y78cU6oo',
            price: 19
          },
          three_month: {
            regular: 'https://buy.stripe.com/test_dR62c07JK3Y78cU6oo',
            professional: 'https://buy.stripe.com/test_dR62c07JK3Y78cU6oo',
            price: 20
          },
          one_year: {
            regular: 'https://buy.stripe.com/test_dR62c07JK3Y78cU6oo',
            professional: 'https://buy.stripe.com/test_dR62c07JK3Y78cU6oo',
            price: 21
          }
        }
      }

    },
    {
      name: 'Pro +',
      sessions: '∞',
      sessionsIcon: <Infinity01Icon color={css`var(--accent-color)`} />,
      programs: <Infinity01Icon color={css`var(--accent-color)`} />,

      paymentLinks: {
        duration: {
          one_month: {
            regular: 'https://buy.stripe.com/test_dR62c07JK3Y78cU6oo',
            professional: 'https://buy.stripe.com/test_dR62c07JK3Y78cU6oo',
            price: 49
          },
          three_month: {
            regular: 'https://buy.stripe.com/test_dR62c07JK3Y78cU6oo',
            professional: 'https://buy.stripe.com/test_dR62c07JK3Y78cU6oo',
            price: 50
          },
          one_year: {
            regular: 'https://buy.stripe.com/test_dR62c07JK3Y78cU6oo',
            professional: 'https://buy.stripe.com/test_dR62c07JK3Y78cU6oo',
            price: 51
          }
        }
      }
    }
  ] : [
    {
      name: 'Lite',
      sessions: (
        <Typography fz="20px" fw="500" lh="1.2" padding="0 6px">
          6
        </Typography>
      ),
      sessionsIcon: null,
      programs: <Cancel02Icon />,

      paymentLinks: {
        duration: {
          one_month: {
            regular: 'https://buy.stripe.com/7sI8zx1iv9hF7e04gA',
            professional: 'https://buy.stripe.com/14kbLJ7GTbpN2XK3cv',
            price: 9
          }
          // three_month: {
          //   regular: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
          //   professional: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
          //   price: 10
          // },
          // one_year: {
          //   regular: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
          //   professional: 'buy_btn_1Qf2MTDfy7pLROjFZsy565HD',
          //   price: 11
          // }
        }
      }
    },
    {
      name: 'Pro',
      sessions: '∞',
      sessionsIcon: <Infinity01Icon color={css`var(--accent-color)`} />,
      programs: <Cancel02Icon />,

      paymentLinks: {
        duration: {
          one_month: {
            regular: 'https://buy.stripe.com/28o7vt0er0L941O14k',
            professional: 'https://buy.stripe.com/28o175d1d65t69W6oy',
            price: 29
          },
          three_month: {
            regular: 'https://buy.stripe.com/14k5nl4uH1Pd7e028n',
            professional: 'https://buy.stripe.com/eVa3fd0er3Xl41ObIU',
            price: 87
          },
          one_year: {
            regular: 'buy_btn_1Qw9OWDfy7pLROjFaVwGYzgN',
            professional: 'https://buy.stripe.com/eVa5nld1d3Xl69W28l',
            price: 348
          }
        }
      }
    },
    {
      name: 'Pro +',
      sessions: '∞',
      sessionsIcon: <Infinity01Icon color={css`var(--accent-color)`} />,
      programs: <Infinity01Icon color={css`var(--accent-color)`} />,

      paymentLinks: {
        duration: {
          one_month: {
            regular: 'https://buy.stripe.com/9AQ7vt8KX51p9m800o',
            professional: 'https://buy.stripe.com/bIY6rp2mz65t2XK7sN',
            price: 49
          },
          three_month: {
            regular: 'https://buy.stripe.com/cN25nl6CPdxV8i47sR',
            professional: 'https://buy.stripe.com/eVa3fdd1dgK77e06oK',
            price: 147
          },
          one_year: {
            regular: 'buy_btn_1Qw9nTDfy7pLROjFI85JCQmr',
            professional: 'https://buy.stripe.com/6oE9DB3qD0L941OcN9',
            price: 588
          }
        }
      }
    }
  ];
  const [getUserProfile] = useLazyGetUserProfileQuery({skip: hasTofetchuser});


  const getCurrentPrice = useCallback(
    (product) => {
      const duration = selectedDurations[product.name];
      const basePrice = product.paymentLinks.duration[duration].price;
      const calculatedPrice = isProfessionalPrice ? basePrice : basePrice * 1.2;
      const roundedPrice = Math.round(calculatedPrice * 10) / 10;
      return roundedPrice.toString().replace('.0', '');
    },
    [isProfessionalPrice, selectedDurations]
  );

  const getCurrentBtnLink = useCallback(
    (product) => {
      const duration = selectedDurations[product.name];
      return isProfessionalPrice
        ? product.paymentLinks.duration[duration].professional
        : product.paymentLinks.duration[duration].regular;
    },
    [isProfessionalPrice, selectedDurations]
  );
  const onCloseCheckout = useCallback(() => {
      onClose?.();
      SubscriptionSignal.value = { ...SubscriptionSignal.value, isSubscribing: false };
      setLocalShowModal(false);
      if(!isSubscribedComputed.value){
        window.location.reload();
      }
    }, [ onClose]);

  const onToggleProfessionnalPrice = useCallback(() => {
    setIsProfessionalPrice(!isProfessionalPrice);
  }, [isProfessionalPrice]);

  const handleProductDurationChange = useCallback((value, product) => {
    setSelectedDurations((prev) => ({
      ...prev,
      [product.name]: value
    }));
  }, []);

  useSignalEffect(() => {
      const nextVisible =  isSubscribingComputed.value;
      setLocalShowModal(nextVisible);
      if (isSubscribedComputed.value){
        getUserProfile().then(() => {
          setHasToFetchUser(false);
        }).catch((error) => {
          // Handle error
        });
      }
    }, [isSubscribingComputed]);

  const subscribedUntil = new Date( userSignal.value?.userSubscription?.subscribedUntil);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const isBlocked = useMemo(() => !hasTofetchuser && (stripePortalUrlComputed.value == null || stripePortalUrlComputed.value === ''),[hasTofetchuser])
  return (
   <>{localShowModal && <ModalCheckoutContainer
      closable
      visible={localShowModal}
      onClose={() =>onCloseCheckout()}
      children={
        <ModalCheckoutContent isSubscribed={isSubscribedComputed.value}>
          {isSubscribedComputed.value && subscribedUntil != null ?
            <SubscribedUntilContainer>
              <SubscribedUntilTextContainer>
              <Typography fz="24px" fzSm="20px"  fw="600" lh="30px">
                {t('subscribed_until_1')}
              </Typography>
              <Typography color={css`var(--accent-color)`} fz="24px" fzSm="20px" fw="600" lh="30px">
                {userSignal.value?.userSubscription?.stripeProduct?.productName}
              </Typography>
              </SubscribedUntilTextContainer>
              <SubscribedUntilTextContainer>
              <Typography fz="24px" fzSm="20px"  fw="600" lh="30px">
                {t('subscribed_until_2')}
              </Typography>
              <Typography color={css`var(--accent-color)`} fz="24px" fzSm="20px" fw="600" lh="30px">
                {subscribedUntil.getDate()+' '+ (months[subscribedUntil.getMonth()]) +' '+ subscribedUntil.getFullYear() }
              </Typography>
              </SubscribedUntilTextContainer>
              <PortalBtn isBlocked={isBlocked} onClick={() => {
                if (!isBlocked) { return window.open(stripePortalUrlComputed.value, '_blank') } else {return null}
              }}>
              <Typography color={css`var(--accent-color)`} fw="600" lh="18px">
                {t('stripe_portal')}
              </Typography>
              </PortalBtn>
            </SubscribedUntilContainer>
            :
            <>
              {products.map((product) => (
                <ProductContainer key={product.name} shouldHighlight={product.name !== 'Lite'}>
                  <Typography fz="24px" fw="600" lh="30px">
                    {product.name}
                  </Typography>
                  <Typography>{product.sessionsIcon || product.sessions} sessions per month</Typography>
                  <Typography>{product.programs} Programs</Typography>
                  <Typography
                    fz="24px"
                    fw="600"
                    lh="30px"
                    color={css`var(--accent-color)`}
                    style={{ justifyContent: 'center', position: 'relative', left: '16px' }}>
                    {getCurrentPrice(product)}€ /{' '}
                    <SelectCheckoutDuration
                      dropdownClassName="checkout-duration"
                      defaultValue={'one_month'}
                      onChange={(value) => handleProductDurationChange(value, product)}>
                      {product.paymentLinks.duration.one_month != null && <Option value="one_month"> {t('subscribed_month')}</Option>}
                      {product.paymentLinks.duration.three_month != null && (
                        <Option value="three_month"> 3 {t('subscribed_month')}</Option>
                      )}
                      {product.paymentLinks.duration.one_year != null && <Option value="one_year"> {t('subscribed_year')}</Option>}
                    </SelectCheckoutDuration>
                  </Typography>

                  {/* <stripe-buy-button
                    style={{ height: '44px', color: 'white' }}
                    buy-button-id={getCurrentBtnId(product)}
                    publishable-key={product.publishableKeyBtn}></stripe-buy-button> */}
                  <ProductButton
                    style={{ height: '44px', color: 'white' }}
                    onClick={() => window.open(getCurrentBtnLink(product), '_blank')}
                  >
                    <Typography fz="16px" fw="600" color="white" lh="14px" customStyles={css`
                      justify-content: center;
                      `} >
                      {t('buy_now')}
                    </Typography>
                  </ProductButton>
                </ProductContainer>
              ))}
              <SwitchBtn
                onToggle={onToggleProfessionnalPrice}
                defaultChecked={true}
                leftIcon={
                  <Typography
                    lh="1.4"
                    whiteSpace="nowrap"
                    color={isProfessionalPrice ? '#FC6453' : null}
                    style={{ transition: 'color 0.5s ease-in-out' }}>
                    {' '}
                    Professional price{' '}
                  </Typography>
                }
                width="40"
                size={20}
                style={{ transition: '0.7s' }}
              />
            </>
          }

        </ModalCheckoutContent>
      }
    />}
   </>
  );
};

export default ModalCheckoutLink;
