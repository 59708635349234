export const baseApiUrl = process.env.REACT_APP_API_URL ?? '';
export const baseAiManagerApiUrl = process.env.REACT_APP_AI_API_URL ?? '';
export enum ApiRoutes {
  AUTH = '/auth',
  CHAT_HISTORIES = '/chat-histories',
  PROGRAM_CHAT_HISTORY = '/program-chat-history',
  EVENT_LOG = '/event-logs',
  GOALS = '/goals',
  USER_GOAL_ACTIONS = '/user-goal-actions',
  INSIGHTS = '/insights',
  PROGRAM_STEPS = '/program-steps',
  PROGRAMS = '/programs',
  PROMPT = '/prompt',
  SESSIONS = '/sessions',
  UPLOAD = '/upload',
  ONBOARDING_MESSAGE = '/user/onboarding-message',
  USER_ACTIONS = '/user-actions',
  USER_ACTIVITY_ANSWERS = '/user-activity-answers',
  USER_NOTIFICATIONS = '/user-notifications',
  USER_PROGRAM_ANSWER = '/user-program-answers',
  USER_SESSION_ANSWERS = '/user-session-answers',
  USER_STEP_ANSWERS = '/user-step-answers',
  USER_SETTING = '/user-setting',
  USER_SETTINGS = '/user-settings',
  USERS = '/users',
  SESSION_FEEDBACK = '/session-feedbacks',
  SESSION_FOLLOW_UP = '/session-follow-ups',
  SESSION_CHAT_HISTORY_FOLLOW_UP = '/chat-history-follow-ups'
}
export enum ApiAIRoutes {
  CSRF = '/csrf',
  COMPLETION = '/completion/'
}
export enum PageRoutes {
  AI_SESSIONS = '/ai-sessions',
  AI_SESSION_HISTORIES = '/ai-session-histories',
  ARTICLES = '/articles',
  HOME = '/home',
  INSIGHTS = '/insights',
  LANDING = '/',
  LOGIN = '/login',
  LUMIA_CHAT = '/lumia-chat',
  PROFILE = '/profile',
  PROGRAMS = '/programs',
  PROGRAMS_LIBRARY = '/programs-library',
  SESSIONS = '/sessions',
  SESSIONS_LIBRARY = '/sessions-library',
  WELCOME = '/welcome',
  TO_DOS_LIBRARY = '/to-dos-library'
}
