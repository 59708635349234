import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { aiManagerApiSlice } from '../api/aiManagerApiSlice';
import { apiSlice } from '../api/apiSlice';
import rootReducer from '../reducers';

export const store = configureStore({
  reducer: {
    ...rootReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [aiManagerApiSlice.reducerPath]: aiManagerApiSlice.reducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware).concat(aiManagerApiSlice.middleware),
  devTools: process.env.REACT_APP_NODE_ENV !== 'production',
});

setupListeners(store.dispatch);
