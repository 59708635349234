import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { pageRoutes } from '../const/routes';
import { baseAiManagerApiUrl, ErrorMessages, PageRoutes } from '../constants';
import { getCookies, removeCookies } from './cookies';


const axiosInstance = axios.create({
  withCredentials: true
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.url && config.url.includes('auth')) {
      return config;
    }
    const jwtToken = getCookies('jwt');
    config.headers.Authorization = `Bearer ${jwtToken}`;

    console.log('baseAiManagerApiUrl ', baseAiManagerApiUrl);
    console.log('config ', config);
    console.log('config.method ', config.method);
    console.log('condition : ', config.url?.includes(baseAiManagerApiUrl));
    if (
      config.url?.includes(baseAiManagerApiUrl) &&
      config.method &&
      config.method.toString() !== 'get' &&
      config.method.toString() !== 'options'
    ) {
      const csrfToken = getCookies('csrftoken');
      console.log('csrfToken : ', csrfToken);
      if (csrfToken !== undefined && csrfToken !== '') {
        config.headers['X-Csrf-Token'] = csrfToken;
      }
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (res) => {

    if (res.status === StatusCodes.INTERNAL_SERVER_ERROR) {
      throw new Error(ErrorMessages.INTERNAL_SERVER_ERROR);
    }
    if (
      res.status === StatusCodes.UNAUTHORIZED &&
      window.location.pathname !== PageRoutes.LOGIN &&
      window.location.pathname !== pageRoutes.PRIVACY_PATH
    ) {
      removeCookies(['user', 'jwt', 'role', 'lang', 'white_label', 'csrftoken']);
      window.location.href = PageRoutes.LANDING;
    }
    return res.data;
  },
  (error: AxiosError) => {
    // Handle CSRF errors
    if (error.response?.status === StatusCodes.FORBIDDEN && error?.message?.includes('CSRF')) {
      console.error('CSRF token validation failed');
    }
    return Promise.reject(error);
  }
);

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    unknown
  > =>
  async ({
    url,
    method,
    data,
    params,
    headers,
    transformResponse
  }: Partial<AxiosRequestConfig>) => {
    try {

      const response = await axiosInstance({
        url: baseUrl + url,
        method,
        data,
        params,
        headers: {
          ...headers,
          ...(method !== 'GET' && method !== 'OPTIONS' && {
            'X-CSRF-Token': getCookies('csrftoken')
          })
        },
        transformResponse
      });
      return { data: response };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response && err.response.status,
          data: err.response ? err.response.data : err.message
        }
      };
    }
  };
